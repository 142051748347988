import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../shared/Modal';
import { ModalTitle } from '../../shared/Modal/ModalTitle';
import { Button } from '../../shared/Button';

export const ScorePointsModal = ({
  showScorePointsModal,
  setShowScorePointsModal,
}) => {
  if (showScorePointsModal) {
    return (
      <Modal
        onClose={() => {
          setShowScorePointsModal(false);
        }}
        data-testid="score-points-modal"
      >
        <ModalTitle>Body v Osobním Skóre?</ModalTitle>
        <div className="text-base sm:text-lg">
          Osobní skóre vyjadřuje na stupnici od 1 do 1000 bodů zhodnocení Vaší
          kredibility. Pokud byste získali nejvyšší hodnotu Osobního skóre, tedy
          1000&nbsp;bodů, znamenalo by to, že jsme na základě získaných údajů
          zjistili Vaši výtečnou platební morálku, nenašli jsme žádné negativní
          údaje a s ohledem na další finanční a sociodemografické údaje lze
          předpokládat, že nebudete mít problém Vaše závazky řádně a včas
          splácet i v případě nepříznivých budoucích situací.
        </div>
        <Button
          onClick={() => {
            setShowScorePointsModal(false);
          }}
          className="w-64"
          data-testid="score-points-modal-button"
        >
          OK
        </Button>
      </Modal>
    );
  }

  return null;
};

ScorePointsModal.propTypes = {
  showScorePointsModal: PropTypes.bool,
  setShowScorePointsModal: PropTypes.func,
};
