import React from 'react';
import { navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import { object } from 'yup';

import { Page } from '../../../../components/shared/Page';
import { agreedWithService } from '../../../../validations';

import { FormGroup } from '../../../shared/form/FormGroup';
import { FormCheckBox } from '../../../shared/form/FormCheckBox';
import { FormGroupError } from '../../../shared/form/FormGroupError';
import { Button } from '../../../shared/Button';
import { useGTMEvent } from '../../../../hooks/useGTMEvent';

import Clockwatch from '../../../../images/svg/clockwatch.svg';
import Calendar from '../../../../images/svg/calendar.svg';
import Info from '../../../../images/svg/info.svg';

const ValidationSchema = object().shape({
  agreedWithService,
});

const IntroItem = ({ component: Component, description }) => (
  <li className="flex flex-col items-center mt-8 sm:mt-0">
    <Component className="w-32" />
    <div className="mt-3 text-lg text-center">{description}</div>
  </li>
);

export const Introduction = () => {
  const pushGTMEvent = useGTMEvent({
    event: 'dashboard:insolvency:register:info',
    eventCategory: 'dashboard',
    eventAction: 'click',
    eventLabel: 'dashboard_insolvency_register_info',
  });

  const onSubmit = () => {
    pushGTMEvent();
    navigate('/moje/insolvence/formular');
  };

  return (
    <Page
      title="Insolvenční rejstřík"
      description="Pomocí insolvenčního rejstříku můžete prověřit své případné záznamy. Vyhledávat můžete pomocí rodného čísla."
    >
      <section className="max-w-3xl mt-0 sm:mt-4 mx-auto">
        <ul className="flex flex-col sm:flex-row justify-around">
          <IntroItem component={Clockwatch} description="Výsledek okamžitě" />
          <IntroItem component={Calendar} description="Aktuální informace" />
          <IntroItem component={Info} description="Detailní informace" />
        </ul>
      </section>

      <Formik
        initialValues={{
          agreedWithService: false,
        }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          isValid,
          dirty,
          errors,
          touched,
          values,
        }) => (
          <Form className="flex flex-col sm:max-w-sm mx-auto mt-6">
            <FormGroup>
              <FormCheckBox
                name="agreedWithService"
                errors={errors}
                touched={touched}
                value={values.agreedWithService}
                onChange={handleChange}
                data-testid="registration-agreed-with-terms"
              >
                Souhlasím s&nbsp;poskytnutím služby
              </FormCheckBox>
              <FormGroupError name="agreedWithTerms" />
            </FormGroup>

            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting || !dirty || !isValid}
              border="normal"
              className="mt-6"
              data-testid="registration-submit"
            >
              Pokračovat
            </Button>
          </Form>
        )}
      </Formik>
    </Page>
  );
};
