import useSWR from 'swr';

export function useVocative(firstName) {
  const { data, isValidating } = useSWR(`/api/vocative/${firstName}`, {
    revalidateOnFocus: false,
  });
  const vocative = data?.vocative;

  return { isValidating, vocative };
}
