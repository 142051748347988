import { useState, useEffect } from 'react';
import useSWR from 'swr';

const swrOptions = {
  suspense: true,
  revalidateOnFocus: false,
};

export const useApplications = () => {
  const [applications, setApplications] = useState([]);

  const { isValidating, data: allApplications } = useSWR(
    '/api/users/me/applications',
    swrOptions
  );

  useEffect(() => {
    if (allApplications) {
      setApplications(allApplications);
    }
  }, [allApplications]);

  return { applications, isValidating };
};
