import maritalStatus1 from './images/socdem/marital-status/1.svg';
import maritalStatus2 from './images/socdem/marital-status/2.svg';
import maritalStatus3 from './images/socdem/marital-status/3.svg';
import maritalStatus4 from './images/socdem/marital-status/4.svg';
import maritalStatus5 from './images/socdem/marital-status/5.svg';
import maritalStatus6 from './images/socdem/marital-status/6.svg';

import education1 from './images/socdem/education/1.svg';
import education2 from './images/socdem/education/2.svg';
import education3 from './images/socdem/education/3.svg';
import education4 from './images/socdem/education/4.svg';
import education5 from './images/socdem/education/5.svg';
import education6 from './images/socdem/education/6.svg';

import housingType1 from './images/socdem/housing-type/1.svg';
import housingType2 from './images/socdem/housing-type/2.svg';
import housingType3 from './images/socdem/housing-type/3.svg';
import housingType4 from './images/socdem/housing-type/4.svg';
import housingType5 from './images/socdem/housing-type/5.svg';
import housingType6 from './images/socdem/housing-type/6.svg';

export default class IconsMaps {
  static maritalStatus = {
    1: maritalStatus1,
    2: maritalStatus2,
    3: maritalStatus3,
    4: maritalStatus4,
    5: maritalStatus5,
    6: maritalStatus6,
  };

  static education = {
    1: education1,
    2: education2,
    3: education3,
    4: education4,
    5: education5,
    6: education6,
  };

  static housingType = {
    1: housingType1,
    2: housingType2,
    3: housingType3,
    4: housingType4,
    5: housingType5,
    6: housingType6,
  };
}
