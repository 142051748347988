import React from 'react';
import PropTypes from 'prop-types';

import privacyPolicy from '../../../../assets/documents/zasady-ochrany-osobnich-udaju.pdf';

import { Modal } from '../../../shared/Modal';
import { Button } from '../../../shared/Button';

export const ConsentModal = ({ setShowConsentModal }) => {
  return (
    <Modal
      onClose={() => {
        setShowConsentModal(false);
      }}
      data-testid="score-points-modal"
    >
      <div className="text-base sm:text-lg">
        Na základě odvolání tohoto souhlasu doručeného na{' '}
        <a
          href="mailto:info@osobniskore.cz"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          info@osobniskore.cz
        </a>
        , bankovní výpisy smažeme. Může se ale stát, že nebudeme moci výpočet
        Vašeho Osobního skóre dokončit. Více informací o zpracování osobních
        údajů a Vašich právech{' '}
        <a
          href={privacyPolicy}
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          zde
        </a>
        .
      </div>
      <Button
        onClick={() => {
          setShowConsentModal(false);
        }}
        className="w-64"
        border="normal"
        data-testid="score-points-modal-button"
      >
        OK
      </Button>
    </Modal>
  );
};

ConsentModal.propTypes = {
  setShowConsentModal: PropTypes.func,
};
