import React from 'react';
import PropTypes from 'prop-types';

import { Card } from '../../../../../components/shared/Card';
import { Recommendations } from './Recommendations';

export const ScoreHintCard = ({ recommendations }) => {
  const hasRecommendations = recommendations?.length > 0;
  return (
    <Card
      disabled={!hasRecommendations}
      header={
        <span data-testid="score-hint-card-title">
          Co ovlivňuje Vaše skóre?
        </span>
      }
    >
      {hasRecommendations ? (
        <Recommendations recommendations={recommendations} />
      ) : (
        <p data-testid="score-hint-card-subtitle">
          Nechte si spočítat své Osobní skóre a my Vám poradíme, jak si ho
          můžete zlepšit.
        </p>
      )}
    </Card>
  );
};

ScoreHintCard.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.object),
};
