import { atom, selector } from 'recoil';

import {
  isUserPersonalInfoFinished,
  isFinancialPersonalInfoFinished,
} from './personalInfo';

export const onboardingStep = atom({
  key: 'onboardingStep',
  default: 'User',
});

export const handleOnboardingStep = selector({
  key: 'handleOnboardingStep',
  get: ({ get }) => {
    let step = 'User';

    if (get(isUserPersonalInfoFinished)) {
      step = 'Financial';
    }

    if (get(isFinancialPersonalInfoFinished)) {
      step = 'Household';
    }

    return step;
  },
});
