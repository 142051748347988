import React, { useEffect, Suspense } from 'react';
import { useMedia } from 'react-use';
import { Router, useMatch } from '@reach/router';
import { PersonalInfo } from './PersonalInfo';
import { BankStatements } from './BankStatements';
import { screens } from 'tailwindcss/defaultTheme';

import { Layout } from '../../../components/shared/Layout';
import { useResetRecoilState, useRecoilRefresher_UNSTABLE } from 'recoil';
import {
  personalInfo,
  getOpenApplication,
  getPersonalInfoData,
} from '../../../state/personalInfo';
import { Spinner } from '../../../components/shared/Spinner';
import { ProgressBar } from '../../../components/shared/ProgressBar';

export const Score = () => {
  const resetPersonalInfo = useResetRecoilState(personalInfo);
  const refreshGetOpenApplication =
    useRecoilRefresher_UNSTABLE(getOpenApplication);
  const refreshGetPersonalInfoData =
    useRecoilRefresher_UNSTABLE(getPersonalInfoData);

  const isWide = useMedia(`(min-width: ${screens.lg})`);

  const matchDefault = useMatch('/moje/skore/');
  const matchPersonal = useMatch('/moje/skore/osobni-udaje');

  const matchPersonalInfo = matchDefault || matchPersonal;
  const matchBankStatements = useMatch('/moje/skore/bankovni-vypisy');
  const matchResults = useMatch('/moje/skore/vysledek');

  const steps = [
    {
      key: '1',
      label: 'Informace o Vás',
      state: matchPersonalInfo ? 'in-progress' : 'pending',
    },
    {
      key: '2',
      label: 'Bankovní výpisy',
      state: matchBankStatements ? 'in-progress' : 'pending',
    },
    {
      key: '3',
      label: 'Vaše skóre',
      state: matchResults ? 'in-progress' : 'pending',
    },
  ];

  useEffect(() => {
    return () => {
      resetPersonalInfo();
      refreshGetOpenApplication();
      refreshGetPersonalInfoData();
    };
  }, [
    resetPersonalInfo,
    refreshGetOpenApplication,
    refreshGetPersonalInfoData,
  ]);

  return (
    <Layout
      asideContent={isWide ? <ProgressBar steps={steps} top /> : null}
      logoVariant={isWide ? 'light' : 'dark'}
    >
      <Suspense fallback={<Spinner size={64} className="mx-auto" />}>
        <Router>
          <PersonalInfo path="/osobni-udaje" default />
          <BankStatements path="/bankovni-vypisy" />
        </Router>
      </Suspense>
    </Layout>
  );
};
