import React from 'react';
import PropTypes from 'prop-types';

import { FormGroupError } from '../FormGroupError';
import { IconSelect } from '../../IconSelect';
import IconsMaps from '../../../../IconsMaps';

const educationOptions = {
  name: 'Rodinný stav',
  registerItems: [
    {
      code: '1',
      name: 'Základní',
      status: 'ACTIVE',
    },
    {
      code: '2',
      name: 'Mám výuční list',
      status: 'ACTIVE',
    },
    {
      code: '3',
      name: 'Středoškolské',
      status: 'ACTIVE',
    },
    {
      code: '4',
      name: 'Vyšší odborné',
      status: 'ACTIVE',
    },
    {
      code: '5',
      name: 'Bakalářské',
      status: 'ACTIVE',
    },
    {
      code: '6',
      name: 'Magisterské / Inženýrské',
      status: 'ACTIVE',
    },
  ],
};

export const FormEducation = ({
  errors,
  touched,
  onChange,
  onBlur,
  selectedEducation,
}) => {
  return (
    <div>
      <span className="text-xl mb-8 block">
        Jaké je Vaše nejvyšší dosažené vzdělání?
      </span>
      {educationOptions && (
        <IconSelect
          name="education"
          options={educationOptions.registerItems}
          errors={errors}
          value={selectedEducation}
          handleChange={onChange}
          handleBlur={onBlur}
          touched={touched}
          iconsMap={IconsMaps.education}
          test={{ 'data-testid': 'education-input' }}
        />
      )}
      <FormGroupError className="text-center pr-8" name="education" />
    </div>
  );
};

FormEducation.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  selectedEducation: PropTypes.string,
};
