import React from 'react';
import PropTypes from 'prop-types';

import { FormGroupError } from '../FormGroupError';
import { IconSelect } from '../../IconSelect';
import IconsMaps from '../../../../IconsMaps';

const maritalStatusOptions = {
  name: 'Rodinný stav',
  registerItems: [
    {
      code: '1',
      name: 'Vdaná / ženatý',
      status: 'ACTIVE',
    },
    {
      code: '2',
      name: 'Svobodná / svobodný',
      status: 'ACTIVE',
    },
    {
      code: '3',
      name: 'Žiji s partnerem',
      status: 'ACTIVE',
    },
    {
      code: '4',
      name: 'Rozvedená / rozvedený',
      status: 'ACTIVE',
    },
    {
      code: '6',
      name: 'Vdova / vdovec',
      status: 'ACTIVE',
    },
    {
      code: '5',
      name: 'Registrované partnerství',
      status: 'ACTIVE',
    },
  ],
};

export const FormMaritalStatus = ({
  errors,
  touched,
  onChange,
  onBlur,
  selectedMaritalStatus,
}) => {
  return (
    <div>
      <span className="text-xl mb-8 block">Jaký je Váš rodinný stav?</span>
      {maritalStatusOptions && (
        <IconSelect
          name="maritalStatus"
          options={maritalStatusOptions.registerItems}
          errors={errors}
          value={selectedMaritalStatus}
          handleChange={onChange}
          handleBlur={onBlur}
          touched={touched}
          iconsMap={IconsMaps.maritalStatus}
          test={{ 'data-testid': 'marital-status-input' }}
        />
      )}
      <FormGroupError className="text-center pr-8" name="maritalStatus" />
    </div>
  );
};

FormMaritalStatus.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  selectedMaritalStatus: PropTypes.string,
};
