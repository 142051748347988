import { useState, useEffect } from 'react';
import useSWR from 'swr';
import format from 'date-fns/format';
import { cs } from 'date-fns/locale';

import { useApplications } from './useApplications';

const swrOptions = {
  suspense: true,
  revalidateOnFocus: false,
};

export const useApplicationScore = () => {
  const [lastApplication, setLastApplication] = useState(null);
  const [scoringDateFinished, setScoringDateFinished] = useState();
  const { applications } = useApplications();

  const { data: score } = useSWR(
    lastApplication?.id
      ? `/api/applications/${lastApplication.id}/score`
      : null,
    swrOptions
  );

  useEffect(() => {
    const finishedApps = applications
      .filter(({ status }) => {
        return 'FINISHED' === status;
      })
      .sort(
        (a, b) =>
          new Date(b.scoringDateFinished) - new Date(a.scoringDateFinished)
      );

    const latestFinishedApp = finishedApps[0];

    if (latestFinishedApp) {
      setLastApplication(latestFinishedApp);

      if (score) {
        setScoringDateFinished(
          format(
            new Date(latestFinishedApp.scoringDateFinished),
            'do MMMM yyyy',
            {
              locale: cs,
            }
          )
        );
      }
    }
  }, [applications, score]);

  return { ...score, scoringDateFinished };
};
