import React from 'react';
import PropTypes from 'prop-types';

import { FormGroupError } from '../FormGroupError';
import { FormInput } from '../FormInput';

export const FormZipCode = ({ onChange, touched, errors, autofocus }) => {
  return (
    <div className="border-grey-1 border-b pb-8">
      <span className="text-xl mb-8 block">Kde bydlíte?</span>
      <FormInput
        type="text"
        name="zipCode"
        placeholder="PSČ"
        className="max-w-xs"
        errors={errors}
        touched={touched}
        onChange={onChange}
        autofocus={autofocus}
        data-testid="form-zip-code-input"
      />
      <FormGroupError name="zipCode" />
    </div>
  );
};

FormZipCode.propTypes = {
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  autofocus: PropTypes.bool,
};
