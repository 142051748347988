import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useRecoilValue } from 'recoil';

import { Page } from '../../../../components/shared/Page';
import { getOpenApplication } from '../../../../state/personalInfo';
// import { KontomaticUpload } from './KontomaticUpload';
import { ManualUpload } from './ManualUpload';
// import { ButtonGroup } from '../../../shared/ButtonGroup';
import api from '../../../../utilities/api';
import { ConsentModal } from './ConsentModal';
// import { useGTMEvent } from '../../../../hooks/useGTMEvent';

export const BankStatements = () => {
  const { id } = useRecoilValue(getOpenApplication);
  // const [showKontomatik, setShowKontomatik] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);

  // const automaticGTMEvent = useGTMEvent({
  //   event: 'dashboard:upload:bank:statement:auto',
  //   eventCategory: 'dashboard',
  //   eventAction: 'upload',
  //   eventLabel: 'dashboard_upload_bank_statement_auto',
  // });
  // const manualGTMEvent = useGTMEvent({
  //   event: 'dashboard:upload:bank:statement:manually',
  //   eventCategory: 'dashboard',
  //   eventAction: 'upload',
  //   eventLabel: 'dashboard_upload_bank_statement_manually',
  // });

  const finish = async () => {
    await api.closeApplication(id);
    navigate('/moje/nastenka');
  };

  // const handleAutomaticStatements = () => {
  //   automaticGTMEvent();
  //   setShowKontomatik(true);
  // };
  // const handleManualStatements = () => {
  //   manualGTMEvent();
  //   setShowKontomatik(false);
  // };

  return (
    <Page
      title="Nahrajte své bankovní výpisy"
      description="Bez toho, abychom poznali, jak s penězi vycházíte, to nezvládneme. Díky nahrání výpisů si ověříme Vaše příjmy a výdaje. Pak už Vám rovnou ukážeme, jak na tom jste. Nahrané výpisy ihned po výpočtu smažeme."
    >
      {showConsentModal && (
        <ConsentModal setShowConsentModal={setShowConsentModal} />
      )}
      {/* <ButtonGroup
        leftButtonLabel="Automatické nahrání"
        rightButtonLabel="Manuálné nahrání"
        onLeftButtonClicked={handleAutomaticStatements}
        onRightButtonClicked={handleManualStatements}
        className="text-center mb-8"
      /> */}
      {/* {showKontomatik && <KontomaticUpload id={id} finish={finish} />}
      {!showKontomatik && <ManualUpload id={id} finish={finish} />} */}

      <ManualUpload id={id} finish={finish} />

      <p
        className="text-center mt-12 text-xl max-w-3xl mx-auto"
        data-testid="upload-consent"
      >
        Pokračováním souhlasíte, že se podíváme na bankovní výpisy za alespoň
        tři předchozí měsíce a na dosud provedené transakce v tomto měsíci.
        Informace slouží k vyhodnocení Vašeho Osobního skóre.{' '}
        <button
          className="ml-1 underline"
          type="button"
          onClick={() => {
            setShowConsentModal(true);
          }}
        >
          Více informací.
        </button>
      </p>
    </Page>
  );
};
