import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../shared/Card';
import { Button } from '../../../../shared/Button';
import ArrowRight from '../../../../../images/svg/arrow-right.svg';
import { formatInterestRate } from '../../../../../utilities/formatNumbers';
import { useGTMEvent } from '../../../../../hooks/useGTMEvent';

export const ZonkyLoan = ({
  estimatedInterestRate,
  estimatedInterestRateOthers,
}) => {
  const pushGTMEvent = useGTMEvent({
    event: 'dashboard:wantszonkyloan',
    eventCategory: 'dashboard',
    eventAction: 'button',
    eventLabel: 'dashboard_wantszonkyloan',
  });

  // We dont have approved version with other bank interest rate yet. Keeping this part commented.
  // const interestRateOthers = formatInterestRate(
  //   Math.round(estimatedInterestRateOthers || estimatedInterestRate + 1)
  // );
  const interestRate = formatInterestRate(estimatedInterestRate);

  return (
    <Card
      header={
        <span data-testid="zonky-loan-card-title">Jaký může být Váš úrok?</span>
      }
    >
      {estimatedInterestRate && (
        <Fragment>
          {/* We dont have approved version with other bank interest rate yet. Keeping this part commented.
           <p data-testid="zonky-loan-card-subtitle">
            S Vaším Osobním skóre byste mohl v bance dostat půjčku s úrokem
            okolo {interestRateOthers}. Na Zonky Vám půjčí za {interestRate}.
          </p> */}
          <p data-testid="zonky-loan-card-subtitle">
            Kreditní skóre určuje, jaký můžete dostat úrok u půjčky. S Vaším
            Osobním skóre Vám na Zonky půjčí za {interestRate}.
          </p>
          <Button
            onClick={pushGTMEvent}
            href={process.env.GATSBY_ZONKY_ONBOARDING_URL}
            target="_blank"
            className="mt-8"
            border="normal"
            data-testid="zonky-loan-card-button"
          >
            CHCI SI PŮJČIT NA ZONKY <ArrowRight className="w-6 h-6 inline" />
          </Button>
        </Fragment>
      )}
      {!estimatedInterestRate && (
        <p data-testid="zonky-loan-card-subtitle">
          Bohužel jsme vyhodnotili, že půjčka by pro Vás byla příliš riziková.
          Výše najdete tipy, jak své Osobní skóre zlepšit. Poté si ho můžete
          spočítat znovu, případně rovnou zažádat o půjčku na Zonky.
        </p>
      )}
    </Card>
  );
};

ZonkyLoan.prototypes = {
  estimatedInterestRate: PropTypes.number,
  estimatedInterestRateOthers: PropTypes.number,
};
