import React from 'react';
import PropTypes from 'prop-types';

import { FormGroupError } from '../FormGroupError';
import { FormInput } from '../FormInput';

export const FormBirthYear = ({ onChange, touched, errors }) => {
  return (
    <div className="border-grey-1 border-b pb-8">
      <span
        className="text-xl mb-8 block"
        data-testid="form-birth-year-question"
      >
        Kdy jste se narodil/a?
      </span>
      <FormInput
        type="number"
        name="birthYear"
        placeholder="Rok narození"
        className="max-w-xs"
        errors={errors}
        touched={touched}
        onChange={onChange}
        data-testid="form-birth-year-input"
      />
      <FormGroupError name="birthYear" />
    </div>
  );
};

FormBirthYear.propTypes = {
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
};
