import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ProgressBar } from './ProgressBar';
import { Chart } from './Chart';

export const Recommendations = ({ recommendations }) => {
  const sorted = recommendations
    .slice(0)
    .sort((a, b) => a.priority - b.priority);
  return (
    <div
      className="grid grid-flow-col gap-x-4 gap-y-8"
      data-testid="recommendations"
    >
      {sorted.map(({ title, message, priority }) => (
        <Fragment key={priority}>
          {priority % 2 === 0 && <Chart className="self-end mb-2" />}
          {priority % 2 === 1 && (
            <ProgressBar className="self-end mx-auto mb-2" />
          )}
          <div className="col-start-2" data-testid="recommendations-item">
            <h4 className="text-xl" data-testid="recommendations-item-title">
              {title}
            </h4>
            <p data-testid="recommendations-item-message">{message}</p>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

const recommendation = PropTypes.shape({
  title: PropTypes.string,
  message: PropTypes.string,
  priority: PropTypes.number,
});

Recommendations.propTypes = {
  recommendations: PropTypes.arrayOf(recommendation),
};
